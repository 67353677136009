import { makeStyles } from "@material-ui/core/styles";
import { useRnrTrafficState, useRnrViewContext } from "../../../Utils/Data/actions/RnrData";
import { Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { RequirePermission } from "../../../Utils/Permissions/RequirePermission";
import LoadingScope from "../../../Components/LoadingScope";
import { MapContainer, useMap, ZoomControl } from "react-leaflet";
import { isMobile } from "react-device-detect";
import BaseTileLayer from "../../../Components/Map/BaseTileLayer";
import { CustomClusterIcon, MapItem, MapViewConfigProvider, useMapIconStyles } from "../Map/MapComponents";
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import { RnrEndDevice, RnrItem } from "./RnrComponents";
import MarkerClusterGroup from "../../../Components/Map/MarkerCluster";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
    },
    rootColumn: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
    },
    map: {
        flex: 1,
    },
    sliceContainer: {
        height: 168,
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        outline: "1.4px solid #5F656D",
        outlineOffset: -1,
        padding: 8,
    },
    header: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
        color: theme.palette.text.primary,
    },
    slices: {
        display: "flex",
    },
}));

function SliceItem({ slice, classes, rnrTrafficStateData, endDevices }) {
    const endDevicesLocal = useMemo(() => {
        return endDevices.filter((endDevice) => endDevice.rnrId === slice.rnrId && endDevice.sliceId === slice.sliceId);
    }, [slice]);

    const width = useMemo(() => endDevicesLocal.length * 77 + 60, [endDevicesLocal]);

    return (
        <div className={classes.sliceContainer} style={{ width: width }}>
            <div className={classes.header}>
                <Typography
                    variant={"subtitle1"}
                    style={{
                        whiteSpace: "nowrap",
                    }}
                >{`${slice.rnrId}`}</Typography>
                <Typography
                    variant={"subtitle1"}
                    style={{
                        whiteSpace: "nowrap",
                    }}
                >{`${slice.sliceId}`}</Typography>
            </div>
            <div className={classes.slices}>
                {endDevicesLocal.map((endDevice, idx) => (
                    <RnrEndDevice
                        device={endDevice}
                        rnrTrafficStateData={rnrTrafficStateData}
                        position={{
                            position: "absolute",
                            height: 90,
                            width: 77,
                            rotation: 0,
                            y: 80,
                            x: idx * 81 + 30,
                            isHorizontal: true,
                        }}
                    />
                ))}
            </div>
        </div>
    );
}

function Slice({ classes, slice, clusterGroupRef, rnrTrafficStateData, endDevices }) {
    return (
        <MapItem
            item={slice}
            clusterGroupRef={clusterGroupRef}
            clusterDisabled={false}
            popupDisabled={false}
            onRenderPopup={(setOpen, setDeviceDimensions, dragging, setDragging, open) => {
                return <SliceItem slice={slice} classes={classes} rnrTrafficStateData={rnrTrafficStateData} endDevices={endDevices} />;
            }}
            onRenderTooltip={(tooltipDirection) => <></>}
            standalonePopup={true}
        />
    );
}

function RnrMapDevice({ device, clusterGroupRef, rnrTrafficStateData }) {
    return (
        <MapItem
            item={device}
            clusterGroupRef={clusterGroupRef}
            clusterDisabled={false}
            popupDisabled={false}
            standalonePopup={true}
            onRenderTooltip={(tooltipDirection) => <></>}
            onRenderPopup={(setOpen, setDeviceDimensions, dragging, setDragging, open) => {
                return (
                    <RnrItem
                        rnrDevice={device}
                        rnrTrafficStateData={rnrTrafficStateData}
                        position={{
                            position: "absolute",
                            height: 45,
                            width: 45,
                            rotation: 0,
                            y: 0,
                            x: 0,
                            isHorizontal: true,
                        }}
                    />
                );
            }}
        />
    );
}

function MapSlices({ slices, clusterGroupRef, classes, rnrTrafficStateData, endDevices }) {
    const map = useMap();

    const slicesWithPosition = useMemo(
        () =>
            slices.map((slice) => {
                const lane = slice?.slice?.position?.lane_description;
                const lat = slice?.slice?.position?.gps?.lat;
                const lon = slice?.slice?.position?.gps?.lon;

                const latlng = new L.LatLng(lat, lon);
                const point = map.project(latlng, map.getZoom());
                point.x = lane === "right" ? point.x + 10 : point.x - 10;

                point.y = lane === "right" ? point.y + 10 : point.y - 10;
                const newLatLng = map.unproject(point, map.getZoom());

                return {
                    ...slice,
                    lat: newLatLng.lat,
                    lon: newLatLng.lng,
                };
            }),
        [slices]
    );

    useEffect(() => {
        if (slicesWithPosition && slicesWithPosition.length > 0) {
            map.fitBounds([slicesWithPosition.filter((slice) => slice.lat && slice.lon).map((slice) => [slice.lat, slice.lon])], { padding: [15, 15] });
        }
    }, [slicesWithPosition]);

    return slicesWithPosition.map(
        (slice) => slice.lat && <Slice key={"slice:" + slice.id} slice={slice} clusterGroupRef={clusterGroupRef} classes={classes} rnrTrafficStateData={rnrTrafficStateData} endDevices={endDevices} />
    );
}

function MapRnrDevices({ rnrDevices, clusterGroupRef, classes, rnrTrafficStateData }) {
    const devicesWithPosition = useMemo(
        () =>
            rnrDevices.map((rnrDevice) => ({
                ...rnrDevice,
                lat: rnrDevice.config.lat,
                lon: rnrDevice.config.lon,
            })),
        [rnrDevices]
    );
    return devicesWithPosition.map((device) => <RnrMapDevice key={device.id} device={device} clusterGroupRef={clusterGroupRef} classes={classes} rnrTrafficStateData={rnrTrafficStateData} />);
}

const createClusterCustomIcon = (cluster, classes) => {
    let worstSeverity = 0;

    return L.divIcon({
        html: ReactDOMServer.renderToString(<CustomClusterIcon cluster={cluster} classes={classes} severity={worstSeverity} isSelected={false} />),
    });
};

function RnrMapViewLocal({ rnrViewContext }) {
    const mapContainerRef = useRef();
    const theme = useTheme();

    const { trafficStates, rnrDriverId, projectDevices, endDevices, domainId, slices, historyLoading, historyData, rnrDevices } = rnrViewContext;
    const [markersInitialized, setMarkersInitialized] = useState(false);
    const markerRef = useRef();

    const classes = useStyles();
    const mapIconClasses = useMapIconStyles();

    const { rnrTrafficStateData, reloadData, error, loading } = useRnrTrafficState(rnrDriverId, historyData);
    return (
        <RequirePermission permission={"rnr__debug_state"} domainID={domainId}>
            <RequirePermission permission={"rnr__view_state"} domainID={domainId}>
                <LoadingScope loading={loading || historyLoading} error={error} showLoading={false} dialog={false}>
                    <div className={classes.root}>
                        <div className={classes.rootColumn}>
                            <MapContainer
                                minZoom={3}
                                maxZoom={18}
                                className={classes.map}
                                fadeAnimation={false}
                                ref={mapContainerRef}
                                zoomControl={false}
                                whenCreated={() => setMarkersInitialized(true)}
                            >
                                <ZoomControl position={isMobile ? "bottomright" : "topleft"} />
                                <BaseTileLayer />
                                <MapViewConfigProvider />

                                {/*<MarkerClusterGroup*/}
                                {/*    animate={false}*/}
                                {/*    spiderfyDistanceMultiplier={15}*/}
                                {/*    showCoverageOnHover={true}*/}
                                {/*    spiderfyOnMaxZoom={true}*/}
                                {/*    iconCreateFunction={(cluster) => createClusterCustomIcon(cluster, mapIconClasses)}*/}
                                {/*    ref={markerRef}*/}
                                {/*    maxClusterRadius={120}*/}
                                {/*    spiderLegPolylineOptions={{*/}
                                {/*        weight: 3,*/}
                                {/*        color: theme.palette.background.default,*/}
                                {/*        opacity: 1,*/}
                                {/*    }}*/}
                                {/*>*/}
                                <MapSlices slices={slices} clusterGroupRef={markerRef} classes={classes} rnrTrafficStateData={rnrTrafficStateData} endDevices={endDevices} />
                                <MapRnrDevices clusterGroupRef={markerRef} rnrTrafficStateData={rnrTrafficStateData} rnrDevices={rnrDevices} classes={classes} />
                                {/*</MarkerClusterGroup>*/}
                            </MapContainer>
                        </div>
                    </div>
                </LoadingScope>
            </RequirePermission>
        </RequirePermission>
    );
}

export default function RnrMapView({}) {
    const rnrViewContext = useRnrViewContext();
    return <RnrMapViewLocal rnrViewContext={rnrViewContext} />;
}
